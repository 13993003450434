import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tags-testimonials-section',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './testimonials-section.component.html',
  styleUrl: './testimonials-section.component.scss',
})
export class TestimonialsSectionComponent {
  testimonials: {
    content: string;
    author: string;
  }[] = [
    {
      content: `I have nothing but great things to say about The Auto Glass Shop. The ADAS system on my 2018 Honda CR-V went out, so I took the car to the dealership for a diagnostic. They told me the error code they found was due to a compatibility issue with the windshield since it is not an OEM part. As a result, the dealership was asking for an unreasonable amount of money for a new windshield and ADAS calibration. I was very confused because I have had this same windshield for two years without any issues. Luckily, I was able to find the Auto Glass Shop and they were kind enough to let me bring my car to them before they opened to inspect the issue (for free, no less). They assured me that the issue was not with the windshield, but the calibration and were able to fit me into the schedule and get the issue resolved within a couple hours. It was great to work with them and I will definitely be coming back!`,
      author: 'Nick',
    },
    {
      content: `We got Bonnie’s number from Subaru dealership and she has been amazing from the start. After the heavy snow days and pretty much our Subaru was not safe to drive anymore. Bonnie instructed me on what steps I need to take for insurance claim and our windshield replacement was done within two days. My husband also showed up bit late but she was patient and understanding of situation. I can’t recommend her enough. If you need to replace a windshield, call this place up and ask for Bonnie!`,
      author: 'Seol',
    },
    {
      content: `We had a rear window shattered over Thanksgiving break while we were in town visiting from about 8 hours away. I must have called every place I could find to try to get the window repaired before we needed to keep going on our travels. I got a hold of the Auto Glass Shop and let me tell you, they went ABOVE AND BEYOND. The window was fairly rare so most people essentially told us they couldn't help for at least two weeks. Let me tell you, they hunted down this window for me, got it delivered the same day, made space that same day to replace it for me, AND charged me WAY LESS than I got quoted anywhere else. I would recommend the Auto Glass Shop to anyone and everyone, these folks are the best!`,
      author: 'April',
    },
  ];

  index = 0;
  numTestimonials = 2;
  currentPage$ = new BehaviorSubject(
    this.testimonials.slice(this.index, this.index + this.numTestimonials)
  );

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe('(max-width: 1100px)').subscribe((result) => {
      if (result.matches) {
        this.numTestimonials = 1;
      } else {
        this.numTestimonials = 2;
      }
      this.next();
    });
  }

  next() {
    const results = [];
    let from = ++this.index % this.testimonials.length;
    while (results.length < this.numTestimonials) {
      results.push(this.testimonials[from]);
      from = ++from % this.testimonials.length;
    }

    this.currentPage$.next(results);
  }

  previous() {
    const results = [];
    let from = --this.index % this.testimonials.length;
    while (results.length < this.numTestimonials) {
      results.push(this.testimonials[from]);
      from = ++from % this.testimonials.length;
    }

    this.currentPage$.next(results);
  }
}
