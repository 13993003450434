import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RequestQuoteComponent } from '../../../_core/forms/request-quote/request-quote.component';

@Component({
  selector: 'tags-hero-section',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  templateUrl: './hero-section.component.html',
  styleUrl: './hero-section.component.scss',
})
export class HeroSectionComponent {
  constructor(public dialog: MatDialog) {}

  openQuoteForm() {
    const dialogRef = this.dialog.open(RequestQuoteComponent, {
      autoFocus: false,
      maxWidth: '50rem',
      maxHeight: '90vh',
    });
  }

  initiateCall() {
    window.open('tel:8473624883');
  }
}
