import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTime } from 'luxon';

@Component({
  selector: 'tags-marketing-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './marketing-footer.component.html',
  styleUrl: './marketing-footer.component.scss',
})
export class MarketingFooterComponent {
  copyrightYear = DateTime.now().year;
}
