import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ContactSectionComponent } from '../../components/contact-section/contact-section.component';
import { HeroSectionComponent } from '../../components/hero-section/hero-section.component';
import { ServicesSectionComponent } from '../../components/services-section/services-section.component';
import { TestimonialsSectionComponent } from '../../components/testimonials-section/testimonials-section.component';

@Component({
  selector: 'tags-landing',
  standalone: true,
  imports: [
    CommonModule,
    HeroSectionComponent,
    TestimonialsSectionComponent,
    ServicesSectionComponent,
    ContactSectionComponent,
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent {}
