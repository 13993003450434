<div class="page-section">
  <div class="section-content" id="testimonials">
    <h2 class="display-font">Testimonials</h2>
    <div class="testimonials-holder">
      <!-- small button -->
      <div class="small-iterate-buttons">
        <button (click)="previous()" mat-icon-button>
          <mat-icon> chevron_left </mat-icon>
        </button>
        <button (click)="next()" mat-icon-button>
          <mat-icon> chevron_right </mat-icon>
        </button>
      </div>

      <button (click)="previous()" class="small-iterate-button" mat-icon-button>
        <mat-icon> chevron_left </mat-icon>
      </button>
      @for (testimonial of currentPage$ | async; track $index) {

      <div class="testimonial card">
        <p>
          {{ testimonial.content }}
        </p>
        <p>- {{ testimonial.author }}</p>
      </div>
      }

      <button (click)="next()" class="small-iterate-button" mat-icon-button>
        <mat-icon> chevron_right </mat-icon>
      </button>
    </div>
  </div>
</div>
