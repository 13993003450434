<div class="page-section">
  <div class="section-content" id="about">
    <div class="text">
      <h1 class="display-font">
        Friendly Staff & Superior Auto Glass Services
      </h1>
      <p>Locally owned and operated for 50+ years</p>
    </div>
    <div class="actions">
      <button
        class="display-font"
        (click)="openQuoteForm()"
        color="primary"
        mat-flat-button
      >
        Get a Quote
      </button>
      <button
        class="display-font"
        (click)="initiateCall()"
        color="primary"
        mat-flat-button
      >
        Call Us
      </button>
    </div>
  </div>
</div>
