<div class="page-section">
  <div class="section-content" id="services">
    <div class="services">
      <h2 class="display-font">Services</h2>
      <ul>
        <li *ngFor="let service of services">{{ service }}</li>
      </ul>
    </div>

    <div class="images">
      <div *ngFor="let image of images" class="image">
        <img [src]="image" />
      </div>
    </div>
  </div>
</div>
