<div *ngIf="!submitted" class="container">
  <h2>Request a Quote</h2>
  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>
  <button
    [disabled]="form.invalid"
    (click)="submit()"
    color="primary"
    mat-flat-button
  >
    Submit
  </button>
</div>

<div *ngIf="submitted" class="container">
  <h3>
    Thanks, we will reach out to you with a quote as soon as we review your
    request!
  </h3>
</div>
