<div class="page-section">
  <div class="section-content container" id="contact">
    <div class="info">
      <div class="contact">
        <h2 class="display-font">Contact Us</h2>

        <div class="contact-detail">
          <span class="material-symbols-outlined icon"> call </span>
          <a href="tel:8473624883">
            <p>+1&nbsp;(847)&nbsp;362&nbsp;&#8209;&nbsp;4883</p>
          </a>
        </div>

        <div class="contact-detail">
          <span class="material-symbols-outlined icon"> map </span>
          <!-- NOTE: or use query https://maps.google.com/?q=760+East+Park+Avenue,+Libertyville,+IL+60048  -->
          <a href="https://maps.app.goo.gl/RL9d8jFMnTjSvAQu9">
            <p>
              760 East Park Avenue
              <br />
              Libertyville, IL 60048
              <br />
              USA
            </p></a
          >
        </div>
      </div>
      <div class="hours">
        <h2 class="display-font">Business Hours</h2>
        <table>
          <tbody>
            <tr>
              <td><strong>Monday:</strong></td>
              <td>8:00&nbsp;AM&nbsp;-&nbsp;5:00&nbsp;PM</td>
            </tr>
            <tr>
              <td><strong>Tuesday:</strong></td>
              <td>8:00&nbsp;AM&nbsp;-&nbsp;5:00&nbsp;PM</td>
            </tr>
            <tr>
              <td><strong>Wednesday:</strong></td>
              <td>8:00&nbsp;AM&nbsp;-&nbsp;5:00&nbsp;PM</td>
            </tr>
            <tr>
              <td><strong>Thursday:</strong></td>
              <td>8:00&nbsp;AM&nbsp;-&nbsp;5:00&nbsp;PM</td>
            </tr>
            <tr>
              <td><strong>Friday:</strong></td>
              <td>8:00&nbsp;AM&nbsp;-&nbsp;5:00&nbsp;PM</td>
            </tr>
            <tr>
              <td><strong>Saturday:</strong></td>
              <td>8:00&nbsp;AM&nbsp;-&nbsp;12:00&nbsp;PM</td>
            </tr>
            <tr>
              <td><strong>Sunday:</strong></td>
              <td>Closed</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <iframe
      id="map"
      class="map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2951.8085113694437!2d-87.94272579999999!3d42.2826108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f96bb0e48d773%3A0xfd75fa3d3bc7a4a2!2sThe%20Auto%20Glass%20Shop%20Inc!5e0!3m2!1sen!2sus!4v1709763531799!5m2!1sen!2sus"
      height="600"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </div>
</div>
