<div class="page-section">
  <div class="section-content">
    <div class="brand">
      <h1 class="display-font">The Auto Glass Shop</h1>
      <p>Libertyville, IL</p>
    </div>

    <div class="links">
      <a *ngFor="let link of links" [href]="link.path" class="display-font">{{
        link.label
      }}</a>
    </div>
  </div>
</div>
