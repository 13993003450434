import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CookieManagerComponent,
  MarketingAppBarComponent,
  MarketingFooterComponent,
} from '@tags/angular';
import LogRocket from 'logrocket';

@Component({
  standalone: true,
  imports: [
    MarketingAppBarComponent,
    MarketingFooterComponent,
    CookieManagerComponent,
    RouterModule,
  ],
  selector: 'tags-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(@Inject(PLATFORM_ID) platformId: any) {
    if (isPlatformBrowser(platformId)) {
      LogRocket.init('au1iyf/the-auto-glass-shop');
    }
  }
}
