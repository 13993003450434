import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tags-services-section',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './services-section.component.html',
  styleUrl: './services-section.component.scss',
})
export class ServicesSectionComponent {
  services = [
    'Windshield Replacement',
    'Chip Repair',
    'Door Regulators',
    'Side Mirrors',
    'Foreign & Domestic',
    'RVs',
    'Heavy Equipment',
    'Semis',
    'Custom Glass Cutting',
    'ADAS Recalibration',
  ];

  images = ['assets/images/lobby.png', 'assets/images/front.png'];
}
