<div class="container" *ngIf="expanded">
  <h3>Cookie Notice</h3>
  <p>
    We use cookies to enhance your browsing experience, serve personalized ads
    or content, and analyze our traffic. By clicking "Accept All", you consent
    to our use of cookies. Visit our Cookie Policy to
    <a href="/cookie-policy">Learn more</a>.
  </p>

  <div class="actions">
    <button mat-flat-button color="primary" (click)="revokeConsent()">
      Accept Necessary Only
    </button>
    <button mat-flat-button color="primary" (click)="giveConsent()">
      Accept All
    </button>
  </div>
</div>

<div class="preview" *ngIf="!expanded" (click)="toggleExpanded()">
  <mat-icon> cookie</mat-icon>
</div>
