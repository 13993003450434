import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'tags-marketing-app-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './marketing-app-bar.component.html',
  styleUrl: './marketing-app-bar.component.scss',
})
export class MarketingAppBarComponent {
  links: { label: string; path: string }[] = [
    {
      label: 'Services',
      path: '#services',
    },
    // {
    //   label: 'Testimonials',
    //   route: '#testimonials'
    // },
    {
      label: 'Contact Us',
      path: '#contact',
    },
    {
      label: 'Map',
      path: '#map',
    },
  ];
}
