<div class="page-section">
  <div class="section-content">
    <p>&copy; {{ copyrightYear }} The Auto Glass Shop</p>
    <!-- <div class="detail contact">
      <h4>Contact</h4>
      <a href="mailto:contactus@tagssite.com">
        <p>contactus&#64;tagssite.com</p>
      </a>
      <a href="tel:+11234567890"><p>+1&nbsp;(123)&nbsp;456&#8209;7890</p></a>
    </div> -->
  </div>
</div>
