import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../_misc/environments/environment';

@Component({
  selector: 'tags-request-quote',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    MatButtonModule,
  ],
  templateUrl: './request-quote.component.html',
  styleUrl: './request-quote.component.scss',
})
export class RequestQuoteComponent {
  submitted = false;

  form = new FormGroup({});
  model = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'name',
      type: 'input',
      props: {
        label: 'Name',
        required: true,
      },
    },
    {
      key: 'email',
      type: 'input',
      props: {
        label: 'Email',
        required: true,
      },
    },
    {
      key: 'phone',
      type: 'input',
      props: {
        label: 'Phone',
        required: true,
      },
    },
    {
      key: 'brand',
      type: 'input',
      props: {
        label: 'Vehicle Brand',
      },
    },
    {
      key: 'model',
      type: 'input',
      props: {
        label: 'Vehicle Model',
      },
    },
    {
      key: 'vin',
      type: 'input',
      props: {
        label: 'VIN',
        required: true,
      },
    },
    {
      key: 'request',
      type: 'input',
      props: {
        required: true,
        label: 'What service do you need?',
      },
    },
    {
      key: 'notes',
      type: 'textarea',
      props: {
        lines: 5,
        label: 'Notes',
      },
    },
  ];

  constructor(private readonly httpClient: HttpClient) {}

  async submit() {
    this.submitted = true;
    const res = await lastValueFrom(
      this.httpClient.post(
        `${environment.functionService}/quote/request-quote`,
        {
          ...this.model,
        }
      )
    );

    console.log(res);
  }
}
